<template>
  <div class="qxb">
    <div class="banner">
      <div class="bannerContainer">
        <div class="left">
          <div class="txt">情绪宝APP</div>
          <p>
            <span
              >情绪宝是一款专为特殊场景设计的心理健康数字产品，适用于不同团体。该系统利用人工智能技术，通过可穿戴设备收集数据，分析团体的生理和心理信息，提供筛查和预警服务。</span
            >
          </p>
          <div class="banner-btn" @click="toPage('reservation')">
            <em>立即预约演示</em>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/product/qxb/light.png" alt="" class="light" />
          <img
            src="../../assets/product/qxb/screen1.png"
            alt=""
            class="screen1"
          />
          <img
            src="../../assets/product/qxb/screen2.png"
            alt=""
            class="screen2"
          />
        </div>
      </div>
    </div>
    <div class="qxbContainer">
      <div class="top">
        <h3>我们的优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/qxb/icon1.png" alt="" /></dt>
            <dd>
              <p>便捷式</p>
              <span
                >针对特殊移动场景的心理健康需求，提供专业的数字化解决方案</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/qxb/icon2.png" alt="" /></dt>
            <dd>
              <p>智能分析</p>
              <span
                >利用人工智能技术，对心理情绪监测可穿戴设备数据进行多维度分析，实现精准的心理健康状态筛查及预警</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/qxb/icon3.png" alt="" /></dt>
            <dd>
              <p>实时反馈</p>
              <span
                >通过app端，将心理健康状态的分析结果及时反馈给受测团体和管理者，提高心理健康管理的效率和效果</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/qxb/icon4.png" alt="" /></dt>
            <dd>
              <p>兼容性</p>
              <span
                >根据心理健康场景需求场景的不同，提供个性化的兼容性方案，帮助受测团体改善心理情绪，提升心理素质</span
              >
            </dd>
          </dl>
        </div>
      </div>
      <div class="bottom">
        <div class="swiper">
          <div class="tabs">
            <div class="left">
              <h4>让情绪可以被看见</h4>
              <p>情绪不同，所见不同</p>
            </div>
            <div class="dots">
              <span>{{
                currentIndex == 0
                  ? "压力过大"
                  : currentIndex == 1
                  ? "有些焦虑"
                  : currentIndex == 2
                  ? "有些低落"
                  : currentIndex == 3
                  ? "状态饱满"
                  : "较为轻松"
              }}</span>
              <i
                :class="currentIndex == 0 ? 'one active' : 'one'"
                @click="setCurrent(0)"
                ><em></em
              ></i>
              <i
                :class="currentIndex == 1 ? 'two active' : 'two'"
                @click="setCurrent(1)"
                ><em></em
              ></i>
              <i
                :class="currentIndex == 2 ? 'three active' : 'three'"
                @click="setCurrent(2)"
                ><em></em
              ></i>
              <i
                :class="currentIndex == 3 ? 'four active' : 'four'"
                @click="setCurrent(3)"
                ><em></em
              ></i>
              <i
                :class="currentIndex == 4 ? 'five active' : 'five'"
                @click="setCurrent(4)"
                ><em></em
              ></i>
            </div>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" :style="currentIndex == 0 ? 'opacity:1' : ''">
                <img src="../../assets/product/qxb/swiper-ipad1.png" alt="" />
                <img src="../../assets/product/qxb/swiper-light1.png" alt="" />
              </div>
              <div class="swiper-slide" :style="currentIndex == 1 ? 'opacity:1' : ''">
                <img src="../../assets/product/qxb/swiper-ipad2.png" alt="" />
                <img src="../../assets/product/qxb/swiper-light2.png" alt="" />
              </div>
              <div class="swiper-slide" :style="currentIndex == 2 ? 'opacity:1' : ''">
                <img src="../../assets/product/qxb/swiper-ipad3.png" alt="" />
                <img src="../../assets/product/qxb/swiper-light3.png" alt="" />
              </div>
              <div class="swiper-slide" :style="currentIndex == 3 ? 'opacity:1' : ''">
                <img src="../../assets/product/qxb/swiper-ipad4.png" alt="" />
                <img src="../../assets/product/qxb/swiper-light4.png" alt="" />
              </div>
              <div class="swiper-slide" :style="currentIndex == 4 ? 'opacity:1' : ''">
                <img src="../../assets/product/qxb/swiper-ipad5.png" alt="" />
                <img src="../../assets/product/qxb/swiper-light5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="bottomList">
          <h4>操作简单，上手更容易</h4>
          <div class="msg">无复杂繁琐的操作</div>
          <div class="rule">
            <div class="rule1">
              <h5>所有历史报告都可查看</h5>
              <p>通过日期筛选选择开始日期到结束日期查看周期报告</p>
            </div>
            <div class="rule2">
              <h5>通过蓝牙连接稳定连接情绪灯</h5>
              <p>通过连接情绪灯按钮产看周围所有的情绪灯设备</p>
            </div>
            <div class="rule3">
              <h5>开始测量可以一键绑定关联报告</h5>
              <p>通过开始测量按钮输入佩戴者姓名、电话、性别即可开始测量</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
export default {
  data() {
    return {
      currentIndex: 0,
      effect: "fade",
    };
  },
  methods: {
    setCurrent(index) {
      this.currentIndex = index;
    },

    getCurrent(e) {
      this.currentIndex = e;
    },

    toPage(name) {
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false;
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  margin-top: -58px;
  position: relative;
  overflow: hidden;
  .bannerContainer {
    width: 1180px;
    height: 100%;
    justify-content: space-between;
    display: flex;
    margin: auto;
    .left {
      width: 521px;
      flex-shrink: 0;
      .txt {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        position: relative;
        margin: auto;
        padding-top: 138px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin: 24px auto 0;

        span {
          display: block;
          width: 521px;
          color: #222;
        }
      }

      .banner-btn {
        margin: 0 auto 0;
        position: absolute;
        bottom: 87px;
        em {
          display: block;
          font-style: normal;
          cursor: pointer;
          background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
          width: 190px;
          height: 52px;
          border-radius: 30px;
          text-align: center;
          line-height: 52px;
          color: #0065ff;
          font-size: 16px;
        }
      }
    }
    .right {
      display: flex;
      align-items: flex-end;
      height: 500px;
      box-sizing: border-box;
      padding-bottom: 35px;
      .light {
        width: 67px;
      }
      .screen1 {
        width: 246px;
      }
      .screen2 {
        width: 333px;
        margin-left: -67px;
      }
    }
  }
}
.qxbContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            width: 100%;
            float: left;
            height: 100%;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }

  .swiper {
    background: rgba(0, 0, 0, 0.88);
    padding: 80px 0 0px;
    .tabs {
      display: flex;
      max-width: 1180px;
      margin: 0 auto;
      align-items: flex-start;
      justify-content: space-between;
      .left {
        h4 {
          font-size: 34px;
          color: #e2e3e5;
          line-height: 49px;
          margin: 0;
        }
        p {
          margin: 23px 0 0;
          font-size: 20px;
          line-height: 36px;
          color: #d3d4d4;
        }
      }
      .dots {
        font-size: 16px;
        color: #ececec;
        padding-top: 15px;
        display: flex;
        align-items: center;
        span {
          padding-right: 17px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          padding: 4px;
          border-radius: 30px;
          margin-right: 4px;
          cursor: pointer;
          em {
            display: inline-block;
            width: 20px;
            border-radius: 30px;
            height: 20px;
          }
        }
        .one em {
          background: #ff3838;
        }
        .two em {
          background: #f7844d;
        }
        .three em {
          background: #d664df;
        }
        .four em {
          background: #4db8f2;
        }
        .five em {
          background: #84cf84;
        }
        i.active {
          border: 1px solid #ececec;
        }
      }
    }
    .swiper-wrapper {
      max-width: 1180px;
      margin: 40px auto 0;
      position: relative;
      height: 670px;
      .swiper-slide {
        position: absolute;
        width: 1180px;
        left: 0;
        top: 0;
        transition: opacity .5s ease-in-out;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: 502px;
        }
        img:last-child {
          height: 670px;
          margin-top: -100px;
        }
      }
    }
  }
  .bottomList {
    background: rgba(0, 0, 0, 0.7);
    padding: 84px 0 80px;
    h4 {
      margin: 0 auto 23px;
      max-width: 1180px;
      font-size: 34px;
      line-height: 49px;
      color: #e2e3e5;
    }
    .msg {
      font-size: 20px;
      line-height: 36px;
      color: #d3d4d4;
      margin: 0 auto 64px;
      max-width: 1180px;
    }
    .rule {
      display: flex;
      margin: 0 auto;
      max-width: 1180px;
      div {
        flex: 1;
        margin-right: 60px;
        background: url(../../assets/product/qxb/rule1.png) no-repeat #000;
        height: 570px;
        border-radius: 18px;
        padding-top: 51px;
        box-sizing: border-box;
        h5 {
          margin: 0 88px 15px;
          text-align: center;
          color: #fff;
          font-size: 22px;
          line-height: 32px;
        }
        p {
          margin: 0 56px;
          color: #fff;
          text-align: center;
          font-size: 16px;
          line-height: 23px;
        }
      }
      .rule1 {
        background: url(../../assets/product/qxb/rule1.png) no-repeat #000;
        background-size: 100% auto;
        background-position-y: bottom;
      }
      .rule2 {
        background: url(../../assets/product/qxb/rule2.png) no-repeat#000;
        background-size: 100% auto;
        background-position-y: bottom;
      }
      .rule3 {
        background: url(../../assets/product/qxb/rule3.png) no-repeat#000;
        background-size: 100% auto;
        background-position-y: bottom;
        margin-right: 0;
      }
    }
  }
}
</style>