<template>
  <div class="monitor">
    <div class="banner">
      <div class="bannerContainer">
        <div class="left">
          <div class="txt">心理情绪监测可穿戴设备</div>
          <p>
            <span
              >基于生理信号和人工智能技术的心理情绪监测可穿戴设备，能够实时、准确地检测和分析用户的情绪状态，如抑郁、应激、焦虑、高压等</span
            >
          </p>
          <div class="banner-btn" @click="toPage('reservation')"><em>立即预约演示</em></div>
        </div>
        <div class="right">
          <img src="../../assets/product/monitor/banner-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="monitorContainer">
      <div class="top">
        <h3>我们的优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/monitor/icon1.png" alt="" /></dt>
            <dd>
              <p>实时性</p>
              <span
                >能够实时采集和分析用户的生理信号，及时反馈用户的情绪状态，避免延误或遗漏</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/monitor/icon2.png" alt="" /></dt>
            <dd>
              <p>准确性</p>
              <span
                >采用了先进的人工智能技术，结合了多种生理信号和情绪模型，提高了情绪识别的准确率和可靠性</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/monitor/icon3.png" alt="" /></dt>
            <dd>
              <p>便捷性</p>
              <span
                >采用了轻便、舒适、易于佩戴的可穿戴设备，不会给用户带来额外的负担或不适感</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/monitor/icon4.png" alt="" /></dt>
            <dd>
              <p>应用性</p>
              <span
                >适用于各种情绪监测与管理，可以为不同场合和目的提供服务，如社区、教育、政企、医疗等</span
              >
            </dd>
          </dl>
        </div>
      </div>
      <div class="bottom">
        <h3>产品功能与特点</h3>
        <img src="../../assets/product/monitor/container.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    downApp(){
      this.$refs.downapp.scrollIntoView({
          behavior: "smooth",
        });
    },

    toPage(name){
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  }
};
</script>
<style lang="less" scoped>
.banner {
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  position: relative;
  margin-top: -58px;
  overflow: hidden;
  .bannerContainer {
    width: 1180px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    .left {
      width: 521px;
      flex-shrink: 0;
      .txt {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        position: relative;
        margin: auto;
        font-weight: 400;
        padding-top: 138px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin: 24px auto 0;

        span {
          display: block;
          width: 521px;
          color: #222;
        }
      }

      .banner-btn {
        position: absolute;
        bottom: 87px;
        em {
          display: block;
          font-style: normal;
          cursor: pointer;
          background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
          width: 190px;
          height: 52px;
          border-radius: 30px;
          text-align: center;
          line-height: 52px;
          color: #0065ff;
          font-size: 16px;
        }
      }
    }
    .right {
      padding-top: 84px;
      display: flex;
      justify-content: flex-end;
      img {
        width: 471px;
        height: 371px;
      }
    }
  }
}
.monitorContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  background-position-y: top;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            float: left;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }
  .bottom {
    text-align: center;
    padding: 84px 0 80px;
    background: rgba(0, 101, 255, 0.06);
    img{
      margin-top: 68px;
      width: 43.7%;
    }
  }
}
</style>