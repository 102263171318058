<template>
  <div class="xfxl">
    <div class="banner">
      <div class="txt">幸福心联平台</div>
      <p>
        <span
          >为了更好地推进社会心理服务工作走向信息化道路，伴随着北京市社会心理服务站点电化教学视频会议系统的建设，北京市社会心理工作联合2021年推出“幸福心联”智慧平台作为市民心理服务的入口</span
        >
      </p>
      <div class="banner-btn" @click="toPage('reservation')"><em>立即预约演示</em></div>
    </div>
    <div class="screenContainer">
      <div class="top">
        <h3>我们的优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/screen/icon1.png" alt="" /></dt>
            <dd>
              <p>多端建设</p>
              <span
                >用户端、政府端、服务站（机构）端、人才端，实现多方协同，满足不同需求</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/screen/icon2.png" alt="" /></dt>
            <dd>
              <p>心理科普</p>
              <span
                >通过线上线下活动、课程、文章等形式，普及心理知识提高心理素养</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/screen/icon3.png" alt="" /></dt>
            <dd>
              <p>心理培训</p>
              <span
                >培养优秀的社会心理服务人才，定期开展心理督导论坛，提高心理服务质量，扩大心理服务覆盖面</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/screen/icon4.png" alt="" /></dt>
            <dd>
              <p>心理推广</p>
              <span
                >打造全民参与、共同关注、互相支持的心理健康生态圈，让更多人享受幸福的心灵生活</span
              >
            </dd>
          </dl>
        </div>
      </div>
      <div class="bottom">
        <h3>产品功能与特点</h3>
        <div class="introList">
          <dl>
            <dt>
              <p>网络办公功能</p>
              <span>充分利用网络高速传输能力，调动各方资源开展线上协作，实现研究问题及时化、合作攻关广泛性</span>
            </dt>
            <dd><img src="../../assets/product/screen/screen1.png" alt=""></dd>
          </dl>
          <dl>
            <dd><img src="../../assets/product/screen/screen2.png" alt=""></dd>
            <dt>
              <p>课程共享功能</p>
              <span>调到首都专家资源，录制多门教学课程，既可以实时传送，实现多地同时上课解答功能，也可以在网络存储，随时调取学习</span>
            </dt>
          </dl>
          <dl>
            <dt>
              <p>动态管理功能</p>
              <span>为各服务站点打造社会心态预警与评估体系，实现动态管理和数据化工作汇报展示</span>
            </dt>
            <dd><img src="../../assets/product/screen/screen3.png" alt=""></dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    toPage(name){
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  }
};
</script>
<style lang="less" scoped>
.banner {
  background: url(../../assets/product/xfxl/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  padding: 0px 0 0px;
  margin-top: -58px;
  height: 500px;
  position: relative;

  .txt {
    max-width: 1180px;
    font-size: 44px;
    line-height: 64px;
    color: #222;
    position: relative;
    margin: auto;
    padding-top: 138px;
  }

  p {
    font-size: 16px;
    line-height: 23px;
    width: 1180px;
    margin: 24px auto 0;

    span {
      display: block;
      width: 521px;
      color: #222;
    }
  }

  .banner-btn {
    width: 1180px;
    margin: 0 auto 0;
    position: absolute;
    bottom: 87px;
    left: 50%;
    transform: translateX(-50%);
    em {
      display: block;
      font-style: normal;
      cursor: pointer;
      background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
      width: 190px;
      height: 52px;
      border-radius: 30px;
      text-align: center;
      line-height: 52px;
      color: #0065ff;
      font-size: 16px;
    }
  }
}
.screenContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  background-position-y: top;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            width: 100%;
            float: left;
            height: 100%;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }
  .bottom {
    text-align: center;
    padding: 84px 0 80px;
    background: rgba(0, 101, 255, 0.06);
    .introList{
      max-width: 1180px;
      margin: 0 auto;
      margin-top: 30px;
      dl{
        margin: 80px 0 0;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: space-between;
        dt{
          width: 452px;
          flex-shrink: 0;
          p{
            margin: 0;
            font-size: 34px;
            line-height: 49px;
            color: #011739;
          }
          span{
            display: block;
            font-size: 20px;
            line-height: 36px;
            margin-top: 23px;
            color: #798A94;
          }
        }
        dd{
          margin: 0;
          img{
            width: 567px;
          }
        }
      }
      dl:first-child{
        img{
          width: 562px
        }
      }
      dl:last-child{
        img{
          width: 643px
        }
      }
    }
  }
}
</style>