<template>
  <div :class="windowWidth >= 1180 ? 'footer' : 'footer mobile'">
    <div class="cooperate">
      <h1>合作流程</h1>
      <h2>流程清晰 紧密落实</h2>
      <div class="lists">
        <dl>
          <dt>
            <img src="../assets/common/cooperate-icon1.png" alt="" srcset="" />
          </dt>
          <dd>
            <p>需求描述</p>
            <span>说明需求场景 ，明确具体需求</span>
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/common/cooperate-icon2.png" alt="" srcset="" />
          </dt>
          <dd>
            <p>专家沟通</p>
            <span>深入需求交流 ，定制项目方案</span>
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/common/cooperate-icon3.png" alt="" srcset="" />
          </dt>
          <dd>
            <p>商务对接</p>
            <span>商务流程沟通，项目启动准备</span>
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/common/cooperate-icon4.png" alt="" srcset="" />
          </dt>
          <dd>
            <p>项目实施</p>
            <span>系统模型开发，正式上线服务</span>
          </dd>
        </dl>
      </div>
    </div>
    <div class="contact">
      <h3 class="title">一键开启点滴心旅程</h3>
      <h6>一点即顺，心有灵犀</h6>
      <div class="form">
        <div class="name">
          <span><i>*</i>联系人</span>
          <el-input v-model="name" placeholder="请输入联系人姓名" />
        </div>
        <div class="name">
          <span><i>*</i>联系电话</span>
          <el-input v-model="mobile" placeholder="请输入联系人手机号" />
        </div>
        <div class="name">
          <span><i>*</i>单位</span>
          <el-input v-model="company" placeholder="请输入您的公司名称" />
        </div>
        <div class="name">
          <span><i>*</i>您想了解</span>
          <el-select v-model="plan" placeholder="请选择">
            <el-option
              v-for="item in planList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </div>
        <div class="submitBtn" @click="submitInfo()">提交</div>
      </div>
    </div>
    <div class="bottom">
      <div class="content">
        <div class="left">
          <div class="txt">
            <p class="title">关于我们</p>
            <span
              v-for="item in aboutList"
              :key="item.name"
              @click="toPage(item.path)"
              >{{ item.name }}</span
            >
          </div>
          <div class="txt">
            <p class="title">解决方案</p>
            <span
              v-for="item in planList"
              :key="item.name"
              @click="toPage(item.path)"
              >{{ item.name }}</span
            >
          </div>
          <div class="txt">
            <p class="title">产品生态</p>
            <span
              v-for="item in appList"
              :key="item.name"
              @click="toPage(item.path)"
              >{{ item.name }}</span
            >
          </div>
          <div class="txt">
            <p class="title">服务与支持</p>
            <span
              v-for="item in serveList"
              :key="item.name"
              @click="toPage(item.path)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="right">
          <p class="address">
            <span>地址：</span>北京市丰台区榴乡路88号院1号写字楼5层
          </p>
          <p class="address"><span>邮箱：</span>info@lxemotion.com</p>
          <div class="phone">18516854707</div>
          <div class="code">
            <el-popover
              placement="top"
              :width="198"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
            >
              <template #reference>
                <dl>
                  <dt
                    @mouseleave="
                      imgHover3 = false;
                      imgHover2 = false;
                      imgHover1 = false;
                    "
                    @mouseenter="
                      imgHover3 = false;
                      imgHover2 = false;
                      imgHover1 = true;
                    "
                  >
                    <img src="../assets/common/codeL.png" alt="" />
                    <div
                      :style="!imgHover1 ? 'display:block;' : 'display:none'"
                      class="imgBg"
                    ></div>
                  </dt>
                  <dd>灵犀情绪公众号</dd>
                </dl>
              </template>
              <template #default>
                <img
                  style="width: 158px"
                  src="../assets/common/codeL.png"
                  alt=""
                />
              </template>
            </el-popover>
            <el-popover
              placement="top"
              :width="198"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
            >
              <template #reference>
                <dl>
                  <dt
                    @mouseleave="
                      imgHover2 = false;
                      imgHover3 = false;
                      imgHover1 = false;
                    "
                    @mouseenter="
                      imgHover2 = true;
                      imgHover3 = false;
                      imgHover1 = false;
                    "
                  >
                    <img src="../assets/common/user.jpg" alt="" />
                    <div
                      :style="!imgHover2 ? 'display:block;' : 'display:none'"
                      class="imgBg"
                    ></div>
                  </dt>
                  <dd>企业微信</dd>
                </dl>
              </template>
              <template #default>
                <img
                  style="width: 158px"
                  src="../assets/common/user.jpg"
                  alt=""
                />
              </template>
            </el-popover>
            <el-popover
              placement="top"
              :width="198"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
            >
              <template #reference>
                <dl>
                  <dt
                    @mouseleave="
                      imgHover3 = false;
                      imgHover2 = false;
                      imgHover1 = false;
                    "
                    @mouseenter="
                      imgHover3 = true;
                      imgHover2 = false;
                      imgHover1 = false;
                    "
                  >
                    <img src="../assets/product/xfxl/ewm.png" alt="" />
                    <div
                      :style="!imgHover3 ? 'display:block;' : 'display:none'"
                      class="imgBg"
                    ></div>
                  </dt>
                  <dd>心理服务小程序</dd>
                </dl>
              </template>
              <template #default>
                <img
                  style="width: 158px"
                  src="../assets/product/xfxl/ewm.png"
                  alt=""
                />
              </template>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="bottomLink">
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >京ICP备18064472号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["windowWidth"],
  data() {
    return {
      planList: [
        { name: "政企解决方案", path: "solution/enterprise" },
        { name: "教育解决方案", path: "solution/education" },
        { name: "医疗解决方案", path: "solution/medicalTreatment" },
        // { name: "政府解决方案", path: "solution/enterprise" },
        // { name: "培训解决方案", path: "solution/enterprise" },
        // { name: "生活解决方案", path: "solution/enterprise" },
      ],
      serveList: [
        { name: "服务咨询", path: "reservation" },
        { name: "建议与反馈", path: "reservation" },
        { name: "投诉举报", path: "reservation" },
      ],
      aboutList: [
        { name: "关于我们", path: "aboutUs" },
        { name: "发展历程", path: "aboutUs" },
        { name: "荣誉资质", path: "aboutUs" },
        { name: "联系我们", path: "aboutUs" },
      ],
      appList: [
        { name: "心理情绪监测可穿戴设备", path: "productMonitor" },
        { name: "情绪压力筛查及危机预警系统", path: "productGroup" },
        { name: "Seemo（犀陌） APP", path: "productSEEMO" },
        { name: "心理服务咨询平台", path: "productXFXL" },
        { name: "幸福心联平台", path: "productScreen" },
        { name: "情绪宝APP", path: "productQxb" },
      ],
      plan: "",
      name: "",
      mobile: "",
      company: "",
      imgHover3: true,
      imgHover2: true,
      imgHover1: true,
    };
  },
  methods: {
    submitInfo() {
      if (this.name == "") {
        this.$message.error("请输入联系人");
      } else if (this.mobile == "" || this.mobile.length != 11) {
        this.$message.error("请输入正确格式的联系方式");
      } else if (this.company == "") {
        this.$message.error("请输入您的单位名称");
      } else if (this.plan == "") {
        this.$message.error("请选择您想了解的内容");
      } else {
        let formData = new FormData();
        //此处需要和后端接收的参数名相同
        formData.append("name", this.name);
        formData.append("phone", this.mobile);
        formData.append("company", this.company);
        formData.append("content", this.plan);
        this.$axios
          .post(
            //后端接口，自行修改
            "https://demo.lingximind.com/admin_api/ow/submit",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message.success(
                "提交成功，请耐心等待工作人员与您电话联系。"
              );
            }
          });
      }
    },
    toPage(name) {
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false;
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.cooperate {
  background: url(../assets/common/cooperate-bg.jpg) no-repeat;
  background-size: auto 100%;
  background-position-x: center;
  padding: 80px 0;
  text-align: center;
  h1,
  h2 {
    margin: 0;
  }
  h1 {
    font-size: 34px;
    line-height: 49px;
    font-weight: 700;
    color: #fff;
  }
  h2 {
    margin-top: 12px;
    font-size: 20px;
    line-height: 400;
    color: #e1e2e3;
    line-height: 29px;
  }
  .lists {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    dl {
      // flex: 1;
      width: 25%;
      text-align: center;
      margin-top: 135px;
      dt {
        width: 124px;
        height: 124px;
        border-radius: 70px;
        display: flex;
        align-items: center;
        margin: auto;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        img {
          width: 40px;
          height: 40px;
        }
      }
      dt:hover {
        background: #0065ff;
      }
      dd {
        // cursor: pointer;
        margin: 46px 0 0;
        p {
          margin: 0 0 24px;
          font-size: 24px;
          line-height: 32px;
          color: #fff;
        }
        span {
          font-size: 16px;
          color: #fff;
          line-height: 23px;
        }
      }
    }
  }
}
.contact {
  background: url(../assets/common/contact-bg.jpg) no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  background-position-y: center;
  padding: 84px 0 80px;
  h3 {
    font-size: 34px;
    text-align: center;
    margin: 0 0 12px;
    color: #011739;
    font-weight: 700;
  }
  h6 {
    margin: 0;
    text-align: center;
    color: #798a94;
    font-weight: 400;
    font-size: 20px;
  }
  .form {
    margin: 40px auto 0;
    width: 450px;
    div.name {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span {
        display: inline-block;
        height: 40px;
        width: 87px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        color: #434d53;
        font-size: 14px;
        i {
          font-style: normal;
          color: #fe4141;
          font-size: 16px;
          margin-right: 6px;
        }
      }
      .el-input,
      .el-select,
      .el-select .el-input.el-input--suffix {
        flex: 1;
        height: 40px;
        border-color: #dbe9f1;
        border-radius: 4px;
      }
    }

    .submitBtn {
      width: 436px;
      margin-left: 14px;
      cursor: pointer;
      margin-top: 33px;
      background: #0065ff;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      line-height: 44px;
    }
  }
}
.bottom {
  background: #454545;
  padding: 80px 0 93px;
  .content {
    max-width: 1180px;
    margin: 0 auto;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    .left {
      display: flex;
      .txt {
        margin-right: 52px;
        p {
          margin: 0 0 24px;
          font-size: 16px;
          line-height: 21px;
        }
        span {
          display: block;
          color: #b8b8b8;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 12px;
          cursor: pointer;
        }
        span:hover {
          color: #fff;
        }
      }
    }
    .right {
      p {
        margin: 0;
        font-size: 18px;
        color: #ccc;
        line-height: 26px;
        font-weight: 350;
        span {
          color: #fff;
        }
      }
      .phone {
        margin-top: 18px;
        width: 305px;
        height: 52px;
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 52px;
        color: #0065ff;
        font-weight: 700;
        padding-left: 64px;
        box-sizing: border-box;
        background: url(../assets/common/icon-phone.png) no-repeat;
        background-size: 100%;
      }
      .code {
        display: flex;
        dl {
          margin: 0;
          margin-right: 33px;
          dt {
            width: 80px;
            height: 80px;
            img {
              width: 100%;
              height: 100%;
              float: left;
            }
          }
          dd {
            margin: 15px 0 0;
            text-align: center;
            color: #fff;
            font-size: 14px;
            line-height: 19px;
            font-weight: 290;
          }
        }
      }
    }
  }

  .bottomLink {
    color: #fff;
    text-align: right;
    line-height: 20px;
    max-width: 1180px;
    margin:20px auto 0;
    a {
      text-align: center;
      color: #fff;
      font-size: 14px;
      text-decoration-line: none;
    }
    a:active {
      color: #fff;
    }
  }
}
.mobile {
  .cooperate {
    padding: 20px 0;
    text-align: center;
    .lists {
      flex-wrap: wrap;
      dl {
        // flex: 1;
        width: 50%;
        text-align: center;
        margin-top: 30px;
        dt {
          width: 62px;
          height: 62px;
          border-radius: 70px;
          display: flex;
          align-items: center;
          margin: auto;
          justify-content: center;
          background: rgba(0, 0, 0, 0.6);
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }
        dt:hover {
          background: #0065ff;
        }
        dd {
          margin: 15px 0 0;
          p {
            margin: 0 0 10px;
            font-size: 16px;
            line-height: 16px;
            color: #fff;
          }
          span {
            font-size: 12px;
            color: #fff;
            line-height: 20px;
          }
        }
      }
    }
  }

  .contact {
    padding: 40px 0 40px;
    h3 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
    }
    .form {
      margin: 20px auto 0;
      width: 90%;
      .submitBtn {
        width: 96%;
      }
    }
  }
  .bottom {
    background: #454545;
    padding: 80px 0 93px;
    .content {
      max-width: 1180px;
      margin: 0 auto;
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .left {
        display: flex;
        width: 100%;
        .txt {
          margin-right: 6px;
          margin-left: 6px;
          p {
            margin: 0 0 12px;
            font-size: 14px;
            line-height: 21px;
          }
          span {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      .right {
        padding-left: 6px;
        p {
          margin: 0;
          font-size: 14px;
          color: #ccc;
          line-height: 26px;
          font-weight: 350;
          span {
            color: #fff;
          }
        }
        .phone {
          margin-top: 18px;
          width: 305px;
          height: 52px;
          margin-bottom: 20px;
          font-size: 30px;
          line-height: 52px;
          color: #0065ff;
          font-weight: 700;
          padding-left: 64px;
          box-sizing: border-box;
          background: url(../assets/common/icon-phone.png) no-repeat;
          background-size: 100%;
        }
        .code {
          display: flex;
          dl {
            margin: 0;
            margin-right: 33px;
            text-align: center;
            text-align: -webkit-center;
            dt {
              width: 80px;
              height: 80px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                margin-top: 0;
              }
            }
            dd {
              margin: 15px 0 0;
              text-align: center;
              color: #fff;
              font-size: 14px;
              line-height: 19px;
              font-weight: 290;
            }
          }
        }
      }
    }

    .bottomLink {
      color: #fff;
      a {
        text-align: center;
        color: #fff;
        text-decoration-line: none;
      }
      a:active {
        color: #fff;
      }
    }
  }
}
</style>
<style lang="less">
.footer {
  .el-select {
    .el-input {
      height: 40px;
    }
  }
}
dl.el-tooltip__trigger {
  text-align: -webkit-center !important;
  dt {
    position: relative;
    cursor: pointer;
    .imgBg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
    }
  }
}
.footer.mobile{
  display: none;
}
</style>