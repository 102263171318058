import { createRouter, createWebHistory } from "vue-router";
// createWebHistory history模式 createWebHashHistory hash模式
import Home from "@/views/home.vue";
import aboutUs from "@/views/aboutUs.vue";
import cooperate from "@/views/cooperate.vue";
import solution from "@/views/solution.vue";
import productXFXL from '@/views/product/xfxl.vue'
import productSEEMO from '@/views/product/seemo.vue'
import productMonitor from '@/views/product/monitor.vue'
import productScreen from '@/views/product/screen.vue'
import productGroup from '@/views/product/group.vue'
import productQxb from '@/views/product/qxb.vue'
import reservation from '@/views/reservation.vue'
import download from '@/views/download.vue'
import Mdownload from '@/views/mobile/download.vue'
import BigScreen from '@/views/screen/bigScreen.vue'
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs
  },
  {
    path: "/cooperate",
    name: "cooperate",
    component: cooperate
  },
  {
    path: "/solution",
    name: "solution",
    component: solution,
    children: [
      {
        path: 'education',
        name: "education",
        component: solution
      },

      {
        path: 'enterprise',
        name: "enterprise",
        component: solution
      },
      {
        path: 'medicalTreatment',
        name: "medicalTreatment",
        component: solution
      }
    ]
  },
  {
    path: "/productXFXL",
    name: "productXFXL",
    component: productXFXL
  },
  {
    path: "/productSEEMO",
    name: "productSEEMO",
    component: productSEEMO
  },
  {
    path: "/productMonitor",
    name: "productMonitor",
    component: productMonitor
  },
  {
    path: "/productScreen",
    name: "productScreen",
    component: productScreen
  },
  {
    path: "/productQxb",
    name: "productQxb",
    component: productQxb
  },{
    path:"/productGroup",
    name:"productGroup",
    component:productGroup
  },{
    path:'/reservation',
    name:"reservation",
    component:reservation
  },{
    path:'/download',
    name:"download",
    component:download
  },{
    path:'/Mdownload',
    name:'Mdownload',
    component:Mdownload
  },{
    path:'/screen',
    name:'BigScreen',
    component:BigScreen
  }
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
  routes: routes
});


 
function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;
 
    if (route.path !== '*') {
      array.push(path);
    }
 
    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }
 
    return array;
  }, []);
}
 
 
getRoutesList(router.options.routes, 'https://www.lxemotion.com/');
function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://www.lxemotion.com')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}
 
// console.log(getRoutesXML())
export default router;

