<template>
  <div class="downloadMobile">
    <div class="banner">
      <h1>点滴灵犀下载中心</h1>
      <div class="tabs">
        <div @click="activeIndex = 0" :class="activeIndex == 0 ? 'active' : ''">
          <img src="../../assets/download/icon-pc.png" alt="" /> PC端
        </div>
        <div @click="activeIndex = 1" :class="activeIndex == 1 ? 'active' : ''">
          <img src="../../assets/download/icon-mobile.png" alt="" />移动端
        </div>
      </div>
    </div>
    <div class="pcList" v-if="activeIndex == 0">
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/情绪压力筛查及危机预警系统-win32-x64-v211202b1.rar'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-windows.png"
          class="icon-windows"
          alt=""
        />
        <p>情绪压力筛查及危机预警系统</p>
      </div>
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/社会心态预警与评估系统-win32-x64-v211202b1.rar'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-windows.png"
          class="icon-windows"
          alt=""
        />
        <p>社会心态预警与评估系统</p>
      </div>
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/新幸福心联-win32-x64-v220915b1.rar'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-windows.png"
          class="icon-windows"
          alt=""
        />
        <p>幸福心联</p>
      </div>
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/HRV%E7%9B%91%E6%B5%8B%E7%B3%BB%E7%BB%9F-win32-x64.202401.zip'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-windows.png"
          class="icon-windows"
          alt=""
        />
        <p>灵犀体检HRV监测系统</p>
      </div>
    </div>
    <div class="mobileList" v-if="activeIndex == 1">
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/lx_team_v2.0.11_211208_release.apk'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-android.png"
          class="icon-phone"
          alt=""
        />
        <span>Android</span>
        <p>情绪压力筛查及危机预警系统</p>
      </div>
      <div
        @click="
          down(
            'https://apps.apple.com/cn/app/seemo%E7%8A%80%E9%99%8C/id6457200332'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-apple.png"
          class="icon-phone"
          alt=""
        />
        <span>IOS</span>
        <p>Seemo（犀陌）App</p>
      </div>
      <div @click="down('https://oss.lingximind.com/apk/seemo1.0.6.apk')">
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-android.png"
          class="icon-phone"
          alt=""
        />
        <span>Android</span>
        <p>Seemo（犀陌）App</p>
      </div>
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/__UNI__2F3BD83__20231018100122.apk'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-android.png"
          class="icon-phone"
          alt=""
        />
        <span>Android</span>
        <p>情绪宝App</p>
      </div>
      <div
        @click="
          down(
            'https://oss.lingximind.com/apk/__UNI__1EC8FAC__20231018093609.apk'
          )
        "
      >
        <img
          src="../../assets/download/icon-down.png"
          class="icon-down"
          alt=""
        />
        <img
          src="../../assets/download/icon-android.png"
          class="icon-phone"
          alt=""
        />
        <span>Android</span>
        <p>情绪宝Lite App</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: 0,
    };
  },
  mounted(){
    if(window.document.documentElement.clientWidth >= 1180){
     this.$router.push({
      path:'/download'
     })
    }
  },
  methods: {
    down(link) {
      window.open(link);
    },
  },
};
</script>
<style lang="less" scoped>
.downloadMobile {
  max-width: 750px;
  margin: 0 auto;
  overflow-x: hidden;
}
.banner {
  width: 100%;
  height: 2.5rem;
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 1080px 250px;
  h1 {
    margin: 0;
    font-size: 0.36rem;
    line-height: 0.52rem;
    padding-top: 0.65rem;
    font-weight: 400;
    color: #222;
    text-align: center;
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    justify-content: space-between;
    div {
      width: 3.6rem;
      height: 0.82rem;
      font-size: 0.2rem;
      line-height: 0.82rem;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 0.16rem 0.16rem 0 0;
      justify-content: center;
      img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.08rem;
      }
    }
    div.active {
      background: rgba(255, 255, 255, 0.6);
    }
  }
}
.pcList {
  div {
    width: 5.43rem;
    height: 3.49rem;
    text-align: center;
    margin: 0 auto;
    img.icon-down {
      width: 0.38rem;
      margin-top: 0.47rem;
      float: right;
      cursor: pointer;
      margin-right: 0.35rem;
    }
    img.icon-windows {
      width: 0.72rem;
      margin-left: 2.35rem;
      margin-right: 2.35rem;
    }
    p {
      font-size: 0.2rem;
      color: #fff;
      margin-top: 0.56rem;
      // background: red;
      margin-top: 0rem;
      height: 0.58rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;
    }
  }
  div:nth-child(1) {
    background: url(../../assets/mobile/download/pc-bg-1.png) no-repeat;
    background-size: 5.43rem 3.49rem;
  }
  div:nth-child(2) {
    background: url(../../assets/mobile/download/pc-bg-2.png) no-repeat;
    background-size: 5.43rem 3.49rem;
  }
  div:nth-child(3) {
    background: url(../../assets/mobile/download/pc-bg-3.png) no-repeat;
    background-size: 5.43rem 3.49rem;
  }
  div:nth-child(4) {
    background: url(../../assets/mobile/download/pc-bg-4.png) no-repeat;
    background-size: 5.43rem 3.49rem;
    margin-bottom: 0.8rem;
  }
}
.mobileList {
  div {
    width: 5.52rem;
    height: 3.58rem;
    text-align: center;
    margin: 0 auto;
    img.icon-down {
      width: 0.38rem;
      margin-top: 0.47rem;
      float: right;
      cursor: pointer;
      margin-right: 0.35rem;
    }
    img.icon-phone {
      width: 0.72rem;
      margin-left: 2.35rem;
      margin-right: 2.35rem;
      margin-bottom: 0;
    }
    span {
      font-size: 0.14rem;
      display: block;
      color: #6a7171;
    }
    p {
      font-size: 0.2rem;
      color: #222;
      margin-top: 0.25rem;
      // background: red;
      margin-top: 0rem;
      height: 0.58rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  div:nth-child(1) {
    background: url(../../assets/mobile/download/phone-bg-1.png) no-repeat;
    background-size: 5.52rem 3.58rem;
  }
  div:nth-child(2) {
    background: url(../../assets/mobile/download/phone-bg-2.png) no-repeat;
    background-size: 5.52rem 3.58rem;
  }
  div:nth-child(3) {
    background: url(../../assets/mobile/download/phone-bg-3.png) no-repeat;
    background-size: 5.52rem 3.58rem;
  }
  div:nth-child(4) {
    background: url(../../assets/mobile/download/phone-bg-4.png) no-repeat;
    background-size: 5.52rem 3.58rem;
  }
  div:nth-child(5) {
    background: url(../../assets/mobile/download/phone-bg-5.png) no-repeat;
    background-size: 5.52rem 3.58rem;
    margin-bottom: 0.8rem;
  }
}
</style>