<template>
  <div class="solution">
    <Education v-if="routerName == '/solution/education'" />
    <Enterprise v-if="routerName == '/solution/enterprise'" />
    <MedicalTreatment v-if="routerName == '/solution/medicalTreatment'" />
  </div>
</template>
<script>
import Education from './solution/education.vue'
import Enterprise from './solution/enterprise.vue'
import MedicalTreatment from './solution/medicalTreatment.vue'
export default {
  components:{
    Education,
    Enterprise,
    MedicalTreatment
  },
  data(){
    return{
      routerName:''
    }
  },
  created(){
    this.routerName = this.$route.path
  },
  methods:{
    setPage(){
      this.routerName = this.$route.path
    }
  },
  watch:{
    "$route.path":"setPage"
  }
}
</script>
<style lang="less" scoped>

</style>