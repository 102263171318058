import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'
import 'animate.css'

import axios from 'axios'
// 引入rem适配移动端
import './utils/useRem.js'

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

console.log(createApp)
const app = createApp(App)
console.log(app)
app.config.globalProperties.$axios = axios
app.use(dataV)
app.use(router)
app.use(ElementPlus)
app.use(animated)
// createApp({ 
//    mounted() {
//   console.log(777)
//     document.dispatchEvent(new Event('render-event'))
//   }
// })
// const Counter = {
//   data() {
//     return {
//     }
//   },
//   mounted() {
//     console.log(666)
//     setTimeout(() => {
//       console.log(6666666)
//       document.dispatchEvent(new Event('render-event'))
//     }, 5000);
//   }
// }
// createApp(Counter)
app.mount('#app')
app.directive('animate', {
  mounted(el, binding) {
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h = document.documentElement.clientHeight || document.body.clientHeight
      if (top < h) {
        if (el.className.indexOf(binding.value) == -1) {
          // 下面单引号中间是一个空格
          el.className = binding.value + ' ' + el.className
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      } else {
        if (el.className.indexOf(binding.value) != -1) {
          el.className = el.className.split(binding.value)[1]
        }

      }
    }
    window.addEventListener('scroll', binding.addClass, true)
    binding.addClass()
  },
  unmounted(el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
