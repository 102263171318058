<template>
  <div :class="windowWidth >= 1180 ? 'cooperate' : 'cooperate small'">
    <div class="banner">
      <div class="banner-main">
        <div class="left">
          <h1>点滴灵犀合作伙伴体系</h1>
          <p>始终坚持以专业、负责任的态度与精神服务我们的伙伴</p>
          <div class="banner-btn" @click="toPage('reservation')">
            立即预约演示
          </div>
        </div>
        <div class="right">
          <img src="../assets/cooperate/banner.png" alt="" />
        </div>
      </div>
    </div>
    <div class="reason" ref="reason">
      <div class="title">为什么与我们合作</div>
      <div class="lists">
        <dl>
          <dt><img src="../assets/cooperate/icon-1.png" alt="" /></dt>
          <dd>
            <h6>先进的产品技术</h6>
            <div class="line"></div>
            <p>
              我们拥有一支技术精湛、经验丰富的团队，不断追求技术创新和卓越的工程能力。我们不仅仅是产品的提供者，更是技术的引领者，通过不断的技术投入和持续的技术改进，确保我们的产品技术始终处于行业领先地位。我们相信，只有提供最先进的技术才能帮助客户更好地解决问题，实现业务的成功。
            </p>
          </dd>
        </dl>
        <dl>
          <dt><img src="../assets/cooperate/icon-2.png" alt="" /></dt>
          <dd>
            <h6>丰富的解决方案</h6>
            <div class="line"></div>
            <p>
              我们与客户建立了长期合作关系，并为客户提供了许多成功的项目解决方案。我们的方案解决方案是根据客户的具体需求定制的，并且可以随着客户的需求而不断演化。我们相信，在与客户的合作中，只有深入了解客户的需求和目标，才能提供最佳的解决方案。
            </p>
          </dd>
        </dl>
        <dl>
          <dt><img src="../assets/cooperate/icon-3.png" alt="" /></dt>
          <dd>
            <h6>开放的合作态度</h6>
            <div class="line"></div>
            <p>
              我们始终秉持开放的合作态度，我们视客户为合作伙伴，与客户共同成长和发展。我们愿意听取客户的反馈和建议，并与客户共同探索新的商业模式和市场机会。我们相信，通过双方共同努力和开放的合作态度，可以实现双赢的局面。
            </p>
          </dd>
        </dl>
      </div>
    </div>
    <div class="case">
      <div class="title" v-animate="'move-up'">服务海量客户的行业成功案例</div>
      <h5 v-animate="'move-up'">良好的应用和完善的服务体系为企业保驾护航</h5>
      <div class="tabImg">
        <dl v-for="item in companyList" :key="item.name">
          <dt><img :src="item.logo" alt="" /></dt>
          <dd>
            <p>{{item.name}}</p>
            <span
              >{{item.intro}}</span
            >
          </dd>
        </dl>
      </div>
    </div>
    <div class="friend">
      <div class="title" v-animate="'move-up'">优秀合作伙伴</div>
      <h5 v-animate="'move-up'">
        点滴灵犀联合优秀合作伙伴，打造为互联网心理健康的共享计算机生态
      </h5>
      <img src="../assets/cooperate/company.png" alt="" />
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      active: 0,
      windowWidth: 0,
      currentScroll: 0,
      fadeShow: false,
      companyList:[{
        logo:require("../assets/cooperate/ch.png"),
        name:'慈海集团',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、大数据、物联网为底层技术基础，为慈海集团解决情绪疏导、情绪解压等难题',
      },{
        logo:require("../assets/cooperate/sxl.png"),
        name:'北京市社会心理工作联合会',
        intro:'点滴灵犀，以云原生、计算服务、大数据为底层技术基础，北京市社会心理工作联合会解决前人线上答题等难题',
      },{
        logo:require("../assets/cooperate/hxq.png"),
        name:'好心情',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、AIGC、物联网为底层技术基础，为好心情解决情绪测评，情绪分析等难题',
      },{
        logo:require("../assets/cooperate/scdx.png"),
        name:'四川大学',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、大数据、物联网为底层技术基础，为四川大学解决情绪测评，情绪筛查等难题',
      },{
        logo:require("../assets/cooperate/ml.png"),
        name:'名流体检',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、大数据、物联网、计算服务为底层技术基础，为名流体检解决体检难题',
      },{
        logo:require("../assets/cooperate/hykg.png"),
        name:'华园控股',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、大数据、物联网为底层技术基础，为华园控股解决员工情绪筛查，情绪测评等难题',
      },{
        logo:require("../assets/cooperate/zjjt.png"),
        name:'中交集团',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、大数据、物联网为底层技术基础，为中交集团解决司机情绪筛查，情绪监测等难题',
      },{
        logo:require("../assets/cooperate/sfdx.png"),
        name:'北京师范大学',
        intro:'点滴灵犀，以云原生、心理情绪AI算法、大数据、物联网为底层技术基础，为北京师范大学解决情绪测评，情绪筛查等难题',
      }]
    };
  },
  created() {
    this.windowWidth = window.document.documentElement.clientWidth;
  },

  mounted() {},
  methods: {
    toPage(name) {
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false;
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveUp {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.move-up {
  transition: transform 0.5s;
  transform: translate(0, 0px) !important;
}
.banner {
  background: url(../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  padding: 0 0 0;
  height: 500px;
  margin-top: -58px;
  box-sizing: border-box;
  .banner-main {
    display: flex;
    margin: auto;
    max-width: 1180px;
    height: 100%;
    position: relative;
    .left {
      padding-top: 58px;
      box-sizing: border-box;
      h1 {
        margin: 0;
        padding-top: 57px;
        font-size: 44px;
        line-height: 64px;
        font-weight: 400;
        color: #222;
      }
      p {
        margin: 0;
        padding-top: 24px;
        color: #222;
        line-height: 23px;
        font-size: 16px;
      }
      .banner-btn {
        cursor: pointer;
        position: absolute;
        bottom: 87px;
        background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
        width: 190px;
        height: 52px;
        border-radius: 30px;
        text-align: center;
        line-height: 52px;
        color: #0065ff;
        font-size: 16px;
      }
    }
    .right {
      padding-top: 63px;
      box-sizing: border-box;
      margin-left: 229px;
      padding-right: 103px;
      img {
        width: 339px;
      }
    }
  }
}
.reason {
  padding: 78px 0 160px;
  .title {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    color: #011739;
    line-height: 49px;
    margin: 0 0 61px;
  }
  .lists {
    display: flex;
    max-width: 1180px;
    margin: auto;
    justify-content: space-between;
    dl {
      text-align: center;
      width: 30%;
      dt {
        text-align: center;
        width: 156px;
        height: 178px;
        margin: 0 auto 8px;
        img {
          width: 100%;
        }
      }
      dd {
        margin: 0;
        h6 {
          text-align: center;
          margin: 0;
          font-size: 16px;
          color: #444;
          line-height: 23px;
          font-weight: 350;
        }
        .line {
          width: 19px;
          height: 1px;
          background: #999;
          margin: 16px auto 8px;
        }
        p {
          margin: 0;
          font-size: 14px;
          text-align: left;
          line-height: 28px;
          color: #666;
        }
      }
    }
  }
}
.case {
  background: #fefefe;
  position: relative;
  padding: 78px 0 160px;
  .title {
    text-align: center;

    transform: translate(0, 80px);
    font-size: 34px;
    font-weight: 700;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
  }
  h5 {
    margin: 0 0 40px;
    transform: translate(0, 80px);
    font-size: 20px;
    line-height: 29px;
    color: #798a94;
    text-align: center;
  }
  .tabBox {
    max-width: 1180px;
    margin: auto;
    .tabs {
      display: flex;
      p {
        flex: 1;
        text-align: center;
        line-height: 61px;
        font-size: 24px;
        color: #0065ff;
        cursor: pointer;
        background: #e0eaff;
        position: relative;
        span {
          position: absolute;
          left: 50%;
          bottom: 0;
          display: block;
          transform: translateX(-50%);
          width: 0px;
          height: 0px;
          border: 10px solid #000;
          border-top-color: transparent;
          border-bottom-color: #fefefe;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
      p.active {
        color: #fff;
        background: #0065ff;
      }
    }
    
  }

    .tabImg {
      display: flex;
      max-width: 1180px;
      margin: auto;
      flex-wrap: wrap;
      justify-content: space-between;
      dl {
        box-sizing: border-box;
        margin: 0;
        padding: 75px 16px 0;
        cursor: pointer;
        width: 286px;
        height: 279px;
        dt {
          margin-bottom: 25px;
          height: 33px;
          img {
            height: 33px;
            float: left;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 0;
            font-size: 20px;
            color: #002a6a;
            padding-bottom: 22px;
          }
          span {
            font-size: 14px;
            color: #6a7171;
            line-height: 20px;
            float: left;
          }
        }
      }
      dl:hover {
        background: linear-gradient(
          360deg,
          #d7e7ff 0%,
          rgba(215, 231, 255, 0) 100%
        );
        border-bottom: 1px solid #0065ff;
      }
    }
}

.friend {
  padding: 0px 0 80px;
  text-align: center;
  .title {
    text-align: center;
    font-size: 34px;
    transform: translate(0, 80px);
    font-weight: 700;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
  }

  h5 {
    margin: 0 0 55px;
    font-size: 20px;
    line-height: 29px;
    transform: translate(0, 80px);
    color: #798a94;
    text-align: center;
  }
  img {
    max-width: 1180px;
    margin: auto;
  }
}

.small {
  .banner {
    padding-left: 10%;
    box-sizing: border-box;
    .left {
      h1 {
        padding-top: 20px;
        font-size: 26px;
        line-height: 30px;
      }
      p {
        margin: 0;
        font-size: 12px;
        padding-top: 10px;
      }
      .banner-btn {
        margin-top: 30px;
      }
    }
    .right {
      display: none;
    }
  }
  .reason {
    padding-top: 40px;
    padding-bottom: 0px;
    .title {
      margin-bottom: 10px;
    }
    .lists {
      flex-wrap: wrap;
      dl {
        width: 100%;
        padding: 0 20px;
        dt {
          margin-bottom: 0;
        }
        dd {
          h6 {
            font-size: 16px;
          }
          p {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .title {
    font-size: 17px;
    line-height: 20px;
  }
  h5 {
    font-size: 12px;
  }
  .case {
    .tabs {
      p {
        margin: 0;
        font-size: 14px;
        line-height: 40px;
        span {
          border: 6px solid #000;
          border-top-color: transparent;
          border-bottom-color: #fefefe;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }
  }

    .tabImg {
      max-width: 1180px;
      margin: auto;
      padding-left: 12px;
      padding-right: 12px;
      dl {
        width: 180px;
        padding: 35px 12px 0;
        height: 200px;
        dt {
          margin-bottom: 12px;
        }
        dd {
          p {
            font-size: 18px;
            padding-bottom: 12px;
          }
          span {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  .friend img {
    width: 98%;
  }
}
</style>