var docEl = document.documentElement;
        var recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
                clientWidth = 750;
            }
            docEl.style.fontSize = clientWidth / 7.5 + 'px';
        };
        recalc();
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        window.addEventListener(resizeEvt, recalc, false);
