<template>
  <div class="pieEcharts">
    <div v-show="isDisplay" :id="randomId" class="echarts" />
    <div v-show="!isDisplay" class="echarts noNum">
      <span style="color:white; font-size:16px">暂无数据</span>
    </div>
  </div>
</template>

<script>
// import $echarts from "echarts";

// import * as $echarts from "echarts"

import echarts from 'echarts'
export default {
  name: 'PieEcharts',
  props: ['title', 'option', 'pieData'],
  data() {
    return {
      randomId: 'pie-dom' + Date.now() + Math.random(),
      myEcharts: null
    }
  },
  computed: {
    isDisplay() {
      if (
        (this.pieData && this.pieData.length > 0) ||
        (this.option && this.option.series && this.option.series.length > 0)
      ) {
        if (
          this.option.series[0].data &&
          this.option.series[0].data.length > 0
        ) {
          return true
        }
        return false
      } else {
        return false
      }
    }
  },
  watch: {
    pieData() {
      const that = this
      that.$nextTick(function() {
        this.initEcharts()
        setTimeout(function() {
          that.myEcharts.resize()
        }, 400)
      })
    },
    option: {
      deep: true,
      handler() {
        const that = this
        that.$nextTick(function() {
          this.initEcharts()
          setTimeout(function() {
            that.myEcharts.resize()
          }, 400)
        })
      }
    }
  },
  mounted() {
    const that = this
    that.initEcharts()

    window.addEventListener(
      'resize',
      () =>
        setTimeout(function() {
          that.myEcharts.resize()
        }, 400),
      true
    )
  },
  methods: {
    initEcharts() {
      const $echartsDOM = document.getElementById(this.randomId)
      const myEcharts = echarts.init($echartsDOM)
      let options = {
        backgroundColor: 'rgba(128, 128, 128, 0)',
        title: {
          text: this.title,
          textStyle: {
            color: 'rgba(0,0,0,0.70)',
            fontSize: 30,
            align: 'center'
          },
          x: 'center',
          y: 'center'
        },
        grid: {
          top: 100,
          left: 0,
          right: 0,
          bottom: 0
        },
        color: [
          '#ff862f',
          '#851dd1',
          '#ff4e6d',
          '#4f89ef',
          '#ffc642',
          '#0040a3',
          '#00bed1',
          '#00d543'
        ],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '50%'],
            center: ['50%', '50%'],
            data: this.pieData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myEcharts.clear()
      if (this.option) {
        options = Object.assign(options, this.option)
      }
      myEcharts.setOption(options, true)
      this.myEcharts = myEcharts
      myEcharts.resize()
    }
  }
}
</script>
<style lang="less" scoped>
.pieEcharts {
  position: relative;
  display: flex;
  justify-content: center;
}
.wrap-container,
.echarts {
  width: 100%;
  height: 100%;
}
.noNum {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
